import React from 'react';

interface Props {
   className?: string;
}

export const ChevronRightIcon: React.FC<Props> = ({ className }) => {
   return (
      <svg className={className} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
         <path d="M9.971 4.72578C10.5612 5.3167 16.3273 11.5257 16.3273 11.5257C16.4767 11.6736 16.5956 11.8509 16.6768 12.047C16.758 12.2431 16.7998 12.454 16.7998 12.667C16.7998 12.88 16.758 13.0909 16.6768 13.287C16.5956 13.4831 16.4767 13.6604 16.3273 13.8083C16.3273 13.8083 10.5612 20.0202 9.971 20.6082C9.38083 21.1991 8.3191 21.2397 7.69081 20.6082C7.06111 19.9796 7.01169 19.1005 7.69081 18.3285L12.9811 12.6684L7.69081 7.00835C7.01169 6.23639 7.06111 5.35581 7.69081 4.72578C8.3191 4.09431 9.38083 4.13341 9.971 4.72578Z" />
      </svg>
   );
};
